const currentDomain = window.location.origin;
const customRedirectUri = currentDomain + '/signin';
const customLogoutRedirectUri = currentDomain + '/logout';

export const msalConfig = {
    auth: {
        clientId: "166be125-d0ee-4975-b243-37644531dccf", // Application (client id) of the App Registration
        authority: "https://login.microsoftonline.com/939e8966-9285-4a9a-9f04-0887efe8aae0",
        redirectUri: customRedirectUri, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: customLogoutRedirectUri, // Indicates the page to navigate after logout.
        validateAuthority: false,
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
}

export const roles = {
    USER: "legal_km"
}
