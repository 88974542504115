<template>
    <div class="Header">
      <b-navbar class="all-sites-nav" v-if="$store.state.user.isAuthorized">
        <b-container>
          <b-navbar-nav class="ml-auto">
            <b-nav-item right href="https://corporate.goodyear.com/us/en/about/mission.html" target="_blank">Strategy Roadmap<font-awesome-icon class="fa-fw" icon="chevron-right" /></b-nav-item>
            <b-nav-item class="logout" v-on:click="$msal.signOut()" right>
              <font-awesome-icon :icon="['fas', 'lock']"></font-awesome-icon> Log Out
            </b-nav-item>
          </b-navbar-nav>
        </b-container>
      </b-navbar>
      <!-- Dark Grey Nav Piece Above -->
      <b-navbar toggleable="lg">
        <b-container id="gynav">
          <b-navbar-brand class="gohome" to="/"><img class="logo" src="./../assets/images/legal-logo.svg" alt="Goodyear Law Dept logo" /></b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <!-- Right aligned nav items -->
          <b-collapse id="nav-collapse" is-nav v-if="$store.state.user.isAuthorized">
            <b-navbar-nav class="ml-auto" fixed="top">
              <b-nav-item right href="https://goodyearcorp.sharepoint.com/sites/go/en-us" class="first" target="_blank">News</b-nav-item>
              <b-nav-item right href="https://goodyearcorp.sharepoint.com/sites/go/en-us/general-counsel/Pages/Legal-Team-Sites.aspx" target="_blank">Departments</b-nav-item>
              <b-nav-item right href="https://teams.microsoft.com/l/channel/19%3A64d6d52c6dc447a0907dc6ec9a7b5248%40thread.skype/tab%3A%3A8be75c1d-22e2-429c-866a-a5705105ae9d?groupId=db5bdaaf-4f81-4569-915b-6f66b237fd5d&tenantId=939e8966-9285-4a9a-9f04-0887efe8aae0" target="_blank">FAQs</b-nav-item>
              <b-nav-item right href="https://goodyearcorp.sharepoint.com/sites/LegalKnowledgeManagement/_layouts/15/search.aspx/siteall?" target="_blank"><font-awesome-icon icon="search" /> Search</b-nav-item>
              <!-- SEARCH BAR CODE COMMENTED OUT
              <b-nav-form>
                <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
                <b-button size="sm" class="my-2 my-sm-0" type="submit"><font-awesome-icon icon="search" /></b-button>
              </b-nav-form> -->
            </b-navbar-nav>
          </b-collapse>
        </b-container>
      </b-navbar>
    </div>
</template>

<!-- Script starts below -->
<script>
export default {
  name: 'BaseHeader',
  data () {
    return {
      windowWidth: 0
    }
  },
  mounted () {
    this.$nextTick(function () {
      window.addEventListener('resize', this.getWindowWidth)
      // Init
      this.getWindowWidth()
    })
  },
  methods: {
    getWindowWidth () {
      this.windowWidth = document.documentElement.clientWidth

      // small screen
      if (this.windowWidth <= 992) {
        // console.log('smaller screen detected!')
        document.getElementById('gynav').classList.add('container-fluid')
        document.getElementById('gynav').classList.remove('container')
      } else {
        // console.log('md-lg screen detected!')
        document.getElementById('gynav').classList.add('container')
        document.getElementById('gynav').classList.remove('container-fluid')
      }
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.getWindowWidth)
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .all-sites-nav.navbar {
    background-color: #333333;
    padding: 0 0 0 0;
    height: 25px;
    overflow-y: hidden;
  }
  .all-sites-nav li, .all-sites-nav a {
    color: #FEDB00;
  }
  .all-sites-nav li:hover, .all-sites-nav li a:hover {
    text-decoration: underline;
  }
  .navbar {
    background-color: #004EA8;
    color: #fff;
    text-transform: uppercase;
    padding: 0rem 1rem;
  }
  .navbar-nav .nav-item, .navbar-nav .nav-item .nav-link {
    color: #fff;
    font-size: 16px;
    border: none;
    box-shadow: none;
  }
  .navbar-nav .nav-item:hover, .navbar-nav .nav-item:hover .nav-link {
    color: #FEDB00;
  }
  .logo {
    height: 60px;
    width: auto;
    padding-left: 1%;
    padding-bottom: 10px;
  }
  .ml-auto .nav-item, .ml-auto .nav-item .nav-link, .ml-auto .nav-item, .ml-auto .nav-item.logout .nav-link {
    color: #FEDB00;
  }
  .navbar .navbar-toggler {
    background-color: rgba(255, 221, 0, 0.95);
    border-radius: 0px;
  }
  .navbar-nav .nav-item:hover .nav-link {
    color: #FEDB00;
    background-color: rgba(23,60,140,0.5);
  }
  .main.navbar-nav .nav-item.first .nav-link {
    border: none;
    box-shadow: none;
  }
  .main.navbar-nav .nav-item .nav-link {
    border-left: 1px solid rgba(255,255,255,0.2);
    background-color: transparent;
    box-shadow: none;
    margin: 12px 0;
    padding: 3px 10px;
  }
  .ml-auto .nav-item, .ml-auto .nav-item .nav-link {
    color: #fff;
  }
</style>
