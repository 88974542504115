<template>
  <div class="access-denied">
      <b-container>
        <b-row>
          <b-col sm="12">
            <h2>You do not have access to this application.</h2>
          </b-col>
        </b-row>
      </b-container>
  </div>
</template>

<script>
  export default {
    name: 'AccessDeniedPage',
    title: 'Access Denied | Legal KM'
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .access-denied {
    min-height: 350px;
    margin-top: 65px;
    text-align: center;
  }
  h2 {
    font-size: 32px;
    color: #333;
  }
  @media (max-width: 768px) {
    .access-denied {
        margin-top: 35px;
    }
  }
  @media (min-width: 992px) {
    .access-denied {
        min-height: 500px;
    }
  }
</style>
