import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      isAuthorized: false
    }
  },
  mutations: {
    authorizeUser(state, payload) {
      state.user.isAuthorized = payload;
    },
  }
});