<template>
  <div class="logout">
    <div class="page-background"></div>
      <b-container>
        <b-row>
          <b-col sm="12">
            <h2>You have successfully logged out.</h2>
          </b-col>
          <b-col sm="12">
            <router-link to="/signin" class="gy-btn btn">Log Back In</router-link>
          </b-col>
        </b-row>
      </b-container>
  </div>
</template>

<script>
export default {
  name: 'LogoutPage',
  title: 'Logged Out | Legal KM'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logout {
  margin-bottom: 25px;
  text-align: center;
}
.logout > .container {
  margin-top: 65px;
  min-height: 350px;
}
h2 {
  font-size: 32px;
  color: #333;
  padding-bottom: 20px;
}
.btn {
  margin-top: 25px;
}

@media (max-width: 768px) {
  .logout > .container {
      margin-top: 35px;
  }
}
@media (min-width: 992px) {
  .logout > .container {
      min-height: 500px;
  }
}
</style>
