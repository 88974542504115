<template>
  <div class="login">
    <div class="container text-center">
      <div class="card">
        <div class="card-body">
          <h1>Click below to use your GoPass credentials.</h1>
          <router-link to="/signin" class="gy-btn btn">Log In</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginPage',
  title: 'Log In | Legal KM'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login {
  color: #333;
}
.login > .container {
  margin-top: 65px;
  min-height: 350px;
}
.page-background{
  background-position: top center;
  background-size: cover;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  z-index: -1;
  opacity: 0.7;
  background-image: url('./../assets/images/goodyear-lobby-md.jpg');
}
.card {
  max-width: 20rem;
  margin: 0 auto;
}
.card h1 {
  font-size: 20px;
}
.card-body .btn {
  margin-top: 50px;
}
h2 {
  font-size: 32px;
  color: #333;
  padding-bottom: 20px;
}
.btn {
  margin-top: 25px;
}

@media (max-width: 445px) {
  .page-background {
    background-image: url('./../assets/images/goodyear-lobby-xs.jpg');
  }
}
@media (min-width:446px) and (max-width: 768px) {
  .page-background {
    background-image: url('./../assets/images/goodyear-lobby-sm.jpg');
  }
}
@media (max-width: 768px) {
  .login > .container {
      margin-top: 35px;
  }
}
@media (min-width: 992px) {
  .login > .container {
      min-height: 500px;
  }
}
@media (min-width:1281px) and (max-width: 1600px) {
  .page-background {
    background-image: url('./../assets/images/goodyear-lobby-lg.jpg');
  }
}
@media (min-width: 1601px) and (max-width: 2500px) {
  .page-background {
    background-image: url('./../assets/images/goodyear-lobby-xl.jpg');
  }
}
</style>
