import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { msalConfig } from './authConfig'
import { MsalPlugin } from './plugins/MSALPlugin'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBalanceScale, faQuestionCircle, faPencilAlt, faDesktop, faFolderOpen, faUsers, faFileAlt, faGavel, faInfoCircle, faClipboard, faSearch, faBook, faArchive, faNewspaper, faGlobe, faChartBar, faBookOpen, faUniversity, faLock, faSearchDollar, faPlayCircle, faPauseCircle, faVideo, faChevronRight, faBookmark, faThumbtack } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { BootstrapVue } from 'bootstrap-vue'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/styles/style.css'

Vue.config.productionTip = false

Vue.use(BootstrapVue)
library.add(faBalanceScale, faQuestionCircle, faPencilAlt, faDesktop, faFolderOpen, faUsers, faFileAlt, faGavel, faInfoCircle, faClipboard, faSearch, faBook, faArchive, faNewspaper, faGlobe, faChartBar, faBookOpen, faUniversity, faLock, faSearchDollar, faPlayCircle, faPauseCircle, faVideo, faChevronRight, faBookmark, faThumbtack)
Vue.component('font-awesome-icon', FontAwesomeIcon)

const msalOptions = {
  msalConfig: msalConfig
}
Vue.use(new MsalPlugin(), msalOptions)

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
