<template>
  <div id="app">
    <BaseHeader/>
    <router-view/>
    <BaseFooter/>
  </div>
</template>

<script>
  import BaseHeader from './components/Header.vue'
  import BaseFooter from './components/Footer.vue'
  
  export default {
    name: "App",
    components: {
      BaseHeader,
      BaseFooter
    }
  };
</script>

<style>
  #app {
    font-family: "Barlow", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
</style>