import * as msal from "@azure/msal-browser";

let msalInstance;

export let msalPluginInstance;

export class MsalPlugin {
    #msalOptions = {};

    install(vue, options) {
        if (!options) {
            throw new Error("MsalPluginOptions must be specified");
        }
        this.#msalOptions = options;
        this.initialize();
        msalPluginInstance = this;
        vue.prototype.$msal = msalPluginInstance;
    }

    initialize() {
        const msalConfig = this.#msalOptions.msalConfig;
        msalInstance = new msal.PublicClientApplication(msalConfig);
    }

    async signIn() {
        const loginRequest = {
            scopes: this.#msalOptions.apiScopes
        };
        await msalInstance.handleRedirectPromise();
        // redirect promise fulfilled only after redirect
        // and getAllAccounts returns an Object also after redirect
        // so we need to get into signIn method twice: on login and after redirect
        if(!this.isAuthenticated()) {
            // no user signed in
            await msalInstance.loginRedirect(loginRequest);
        }
    }

    async signOut() {
        // The account's ID Token must contain the login_hint optional claim to avoid the account picker
        await msalInstance.logoutRedirect({account: msalInstance.getAllAccounts()[0]});
    }

    async signOutAndAccessDenied() {
        await msalInstance.logoutRedirect({
            account: msalInstance.getAllAccounts()[0],
            postLogoutRedirectUri: window.location.origin + '/access-denied'
        });
    }

    isAuthenticated() {
        const accounts = msalInstance.getAllAccounts();
        return accounts && accounts.length > 0;
    }

    isAuthorized(role) {
        let isAuthorized = false;
        const currentAccount = msalInstance.getAllAccounts()[0];
        if (currentAccount && currentAccount.idTokenClaims['roles']) {
            isAuthorized = currentAccount.idTokenClaims['roles'].includes(role);
        }
        return isAuthorized;
    }
}