<template>
  <div class="home">
    <div class="separator"></div>

    <section class="main-section">
      <div class="page-background"></div>
      <b-container>
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12">
            <b-row>
            <img class="bannerimg" src="./../assets/images/legal-compliance-banner.png" alt="Goodyear Law Dept logo" />
            </b-row>
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="3">
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="6">
            <div>
              <a href="https://goodyearcorp.sharepoint.com/sites/LegalKnowledgeManagement/_layouts/15/news.aspx?newsSource=1&instanceId=f7bfdec9-09c5-4fb6-bc97-3ba225d35ad4&webPartId=8c88f208-6c77-4bdb-86a0-0c47b4316588&serverRelativeUrl=%2Fsites%2FLegalKnowledgeManagement&pagesListId=613ff730-1fdd-4353-bed7-d1d5d14a575f" target="_blank">
              <img class="newstile1" src="./../assets/images/news-tile3.png" alt="Goodyear Law Dept logo" />
              <div class="centered3"><p>Department Updates</p></div></a>
            </div>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3">
          </b-col>
        </b-row>
        <div class="separator"></div>
        <b-row>
          <b-col cols="4" xs="4" sm="4" md="4">
              <a href="https://goodyearcorp.sharepoint.com/sites/LegalKnowledgeManagement" target="_blank">
              <img class="social-media1" src="./../assets/images/sharepoint-logo3.png" alt="Goodyear Law Dept logo" /></a>
          </b-col>
          <b-col cols="4" xs="4" sm="4" md="4">
              <a href="https://teams.microsoft.com/l/channel/19%3a64d6d52c6dc447a0907dc6ec9a7b5248%40thread.skype/General?groupId=db5bdaaf-4f81-4569-915b-6f66b237fd5d&tenantId=939e8966-9285-4a9a-9f04-0887efe8aae0" target="_blank">
              <img class="social-media" src="./../assets/images/teams-logo2.png" alt="Goodyear Law Dept logo" /></a>
          </b-col>
          <b-col cols="4" xs="4" sm="4" md="4">
              <a href="https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIzODAyMjg4MTI4MCJ9/all" target="_blank">
              <img class="social-media2" src="./../assets/images/yammer-logo2.png" alt="Goodyear Law Dept logo" /></a>
          </b-col>
        </b-row>
        <div class="separator"></div>
          </b-col>
          <!--RSS Feed Removal
          <b-col cols="12" xs="12" sm="12" md="4">
              <rssfeed v-if="isModernBrowser"/>
              <div class="NewsHeader">
              <p><b>Recent Legal News </b></p>
              <div class="vue-rss-feed" cols="12" sm="6" md="4">
                <div class="row">
                   <VueRssFeed feedUrl="https://cors-anywhere.herokuapp.com/http://feeds.feedburner.com/abajournal/topstories?format=xml" :name="rssName" :limit="4"/>
                </div>
              </div>
            </div>
          </b-col>-->
        </b-row>
      </b-container>
      <div class="separator"></div>
    </section>

    <section class="prod-section">
      <b-container fluid >
        <div class="link-section">
        <b-row>
          <b-col class="icon-knowledge-share" cols="12" md="3">
            <h4>Knowledge Sharing</h4>
            <ul>
            <li><font-awesome-icon class="fa-fw" icon="newspaper" /><a href="https://goodyearcorp.sharepoint.com/sites/LegalKnowledgeManagement/Lists/Articles/ArticlesView.aspx" class="icon-link" target="_blank">Articles</a></li>
            <li><font-awesome-icon class="fa-fw" icon="question-circle" /><a href="https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIzODAyMjg4MTI4MCJ9/all" class="icon-link" target="_blank">Ask Questions</a></li>
            <li><font-awesome-icon class="fa-fw" icon="folder-open" /><a href="https://goodyearcorp.sharepoint.com/sites/LegalKnowledgeManagement/Memos%20and%20Guidance/Forms/AllItems.aspx" class="icon-link" target="_blank">Memos and Guidance</a></li>
            <li><font-awesome-icon class="fa-fw" icon="pencil-alt" /><a href="https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIzODAyMjg4MTI4MCJ9/all" class="icon-link" target="_blank">Post</a></li>
            <li><font-awesome-icon class="fa-fw" icon="desktop" /><a href="https://goodyearcorp.sharepoint.com/sites/Go/EN-US/it/Pages/default.aspx" class="icon-link" target="_blank">Technology</a></li>
          </ul>
          </b-col>
          <b-col class="icon-knowledge-share" cols="12" md="3">
            <h4>Secretary's Office & Records</h4>
            <ul>
            <li><font-awesome-icon class="fa-fw" icon="search" /><a href="https://goodyearcorp.sharepoint.com/sites/LegalKnowledgeManagement/Lists/ContractSearch/newform.aspx" class="icon-link" target="_blank">Contract Search</a></li>
            <li><font-awesome-icon class="fa-fw" icon="clipboard" /><a href="https://goodyearcorp.sharepoint.com/sites/LegalKnowledgeManagement/Lists/ContractSubmission/newform.aspx" class="icon-link" target="_blank">Contract Submission</a></li>
            <li><font-awesome-icon class="fa-fw" icon="users" /><a href="pdfs/delegations-of-authority-general-information-final.pdf" class="icon-link" target="_blank">Delegations</a></li>
            <li><font-awesome-icon class="fa-fw" icon="gavel" /><a href="https://gems.computershare.com/Login.aspx?ReturnUrl=%2f" class="icon-link" target="_blank">Entity Management System (GEMS)</a></li>
            <li><font-awesome-icon class="fa-fw" icon="file-alt" /><a href="https://www.sec.gov/cgi-bin/browse-edgar?CIK=42582" class="icon-link" target="_blank">SEC Filings</a></li>
            <li><font-awesome-icon class="fa-fw" icon="info-circle" /><a href="https://goodyearcorp.sharepoint.com/sites/LegalKnowledgeManagement/Lists/Key%20Company%20Facts/AllItems.aspx" class="icon-link" target="_blank">Key Company Facts</a></li>
          </ul>
          </b-col>
          <b-col class="icon-knowledge-share" cols="12" md="3">
            <h4>Compliance & Ethics</h4>
            <ul>
            <li><font-awesome-icon class="fa-fw" icon="search-dollar" /><a href="https://goodyearcorp.sharepoint.com/sites/go/en-us/ethics/Pages/bcm.aspx" class="icon-link" target="_blank"> BCM</a></li>
            <li><font-awesome-icon class="fa-fw" icon="video" /><a href="https://web.microsoftstream.com/group/9d440c6b-8ac0-4251-9d76-3c9b20d756d1?view=videos" class="icon-link" target="_blank"> C&E Videos</a></li>
            <li><font-awesome-icon class="fa-fw" icon="globe" /><a href="https://goodyear.ethicspointvp.com/" class="icon-link" target="_blank">NAVEX</a></li>
            <li><font-awesome-icon class="fa-fw" icon="book" /><a href="https://goodyearcorp.sharepoint.com/sites/go/en-us/policies" class="icon-link" target="_blank">Policies</a></li>
            <li><font-awesome-icon class="fa-fw" icon="lock" /><a href="http://duediligence.goodyear.com" class="icon-link" target="_blank">Securimate</a></li>
          </ul>
          </b-col>
          <b-col class="icon-knowledge-share" cols="12" md="3">
            <h4>Resources</h4>
            <ul>
            <li><font-awesome-icon class="fa-fw" icon="book-open" /><a href="https://goodyearcorp.sharepoint.com/sites/LegalKnowledgeManagement/Lists/CLE%20events/CurrentCLE.aspx" class="icon-link" target="_blank">Continuing Legal Education</a></li>
            <li><font-awesome-icon class="fa-fw" icon="balance-scale" /><a href="https://ec.europa.eu/dgs/competition/index_en.htm" class="icon-link" target="_blank">European Commission - Antitrust</a></li>
            <li><font-awesome-icon class="fa-fw" icon="chart-bar" /><a href="https://www.lexisnexis.com/en-us/home.page" class="icon-link" target="_blank">LEXIS</a></li>
            <li><font-awesome-icon class="fa-fw" icon="university" /><a href="https://www.pli.edu/" class="icon-link" target="_blank">PLI</a></li>
            <li><font-awesome-icon class="fa-fw" icon="bookmark" /><a href="https://signon.thomsonreuters.com/?productid=PLCUS&viewproductid=PLCUS&lr=0&culture=en-US&returnto=https%3a%2f%2f1.next.westlaw.com%2fCosi%2fSignOn%3fisplcus%3dtrue%26startPage%3d%252fBrowse%252fHome%252fPracticalLaw%253ftransitionType%253dDefault%2526contextData%253d(sc.Default)&tracetoken=0817200757090B-xzHMYPIYqWS3YTBfKp5aoo3MtTtU5KNEn51HLdzgLV1PcBjQg64O7-kD1_37FOTbfcZH8gbSSSrmbJaZ9FjJ3GoMM5idu2IJxf5FfaXgRBS2wm3ZGSm0MoOuMAN8Ie7MwhCW1iZ006GIfzPMhy2ziWZZNW63x977RCHSHspZysxw9M-XFgbJjnPxmAAiJAjLZAYpVh-pvlNrgIy4cESn6N9qHxGwZq-_bSOk0ozaVBYNxgSLrJbz7CYwzwdBdf6iv5pXlxQ1Hzw3j1k1NpFfKp63bY6ST77uDir7Sqpe-7y8Xsn9TAXYBFVAL9J8nBjmvmnjOv_HerxTkkhOvgqfzBcgXN_ufZNGOog-8KLOI&bhcp=1" class="icon-link" target="_blank">Practical Law - US</a></li>
            <li><font-awesome-icon class="fa-fw" icon="thumbtack" /><a href="https://uk.practicallaw.thomsonreuters.com/" class="icon-link" target="_blank">Practical Law - UK</a></li>
          </ul>
          </b-col>
        </b-row>
        </div>
      </b-container>
    </section>

    <section class="fourblock">
      <b-row>
        <b-col class ="fourblockimages" cols="12" sm="6" md="3">
        <a href="https://easyconnect.doelegal.com/" target="_blank">
        <img class="block-img" src="./../assets/images/ascent-billing1.jpg" alt="Goodyear Law Dept logo" />
        <div class="centered"><p>Ascent (eBilling)</p></div></a></b-col>
        <b-col class ="fourblockimages" cols="12" sm="6" md="3"><a href="https://sts.goodyear.com/adfs/ls/idpinitiatedsignon.aspx?loginToRp=https://solutions.sciquest.com" target="_blank"><img class="block-img" src="./../assets/images/gcs1.jpg" alt="Goodyear Law Dept logo" />
        <div class="centered"><p>GCS</p></div></a></b-col>
        <b-col class ="fourblockimages" cols="12" sm="6" md="3"><a href="https://performancemanager4.successfactors.com/sf/home?bplte_company=Goodyear&_s.crb=yxVN8%252fVE5zqTfIEZ7UM3Zac9PgRjQs1KHVF6AxBzRl4%253d#Shell-home" target="_blank"><img class="block-img" src="./../assets/images/gtms1.jpg" alt="Goodyear Law Dept logo" />
        <div class="centered"><p>GTMS</p></div></a></b-col>
        <b-col class ="fourblockimages" cols="12" sm="6" md="3"> <a href="https://goodyearcorp.sharepoint.com/sites/go/en-us/GBS/Pages/Travel-%26-Expense.aspx" target="_blank">
        <img class="block-img" src="./../assets/images/expenses1.jpg" alt="Goodyear Law Dept logo" />
        <div class="centered"><p>Travel and Expense</p></div></a></b-col>
      </b-row>
    </section>

    <section class="doc-library">
      <div class="button-section">
        <h4>Document Libraries</h4>
        <a href="https://goodyearcorp.sharepoint.com/sites/LegalKnowledgeManagement/Americas/Forms/AllItems.aspx" target="_blank">
        <b-button class="button1" squared variant="outline-primary">Americas</b-button></a>
        <a href="https://goodyearcorp.sharepoint.com/sites/LegalKnowledgeManagement/AP%20Document%20Library/Forms/AllItems.aspx" target="_blank">
        <b-button class="button1" squared variant="outline-primary">AP</b-button></a>
        <a href="https://goodyearcorp.sharepoint.com/sites/LegalKnowledgeManagement/Compliance%20%20Ethics/Forms/AllItems.aspx" target="_blank">
        <b-button class="button1" squared variant="outline-primary">Compliance & Ethics</b-button></a>
        <a href="https://goodyearcorp.sharepoint.com/sites/LegalKnowledgeManagement/Corporate/Forms/AllItems.aspx" target="_blank">
        <b-button class="button1" squared variant="outline-primary">CORP</b-button></a>
        <a href="https://goodyearcorp.sharepoint.com/sites/EMLeCompl/LgL-Compl/SitePages/Home.aspx" target="_blank">
        <b-button class="button1" squared variant="outline-primary">EMEA</b-button></a>
        <a href="https://goodyearcorp.sharepoint.com/sites/LegalKnowledgeManagement/Government%20Affairs/Forms/AllItems.aspx" target="_blank">
        <b-button class="button1" squared variant="outline-primary">Government Affairs</b-button></a>
        <a href="https://goodyearcorp.sharepoint.com/sites/LegalKnowledgeManagement/Intellectual%20Property/Forms/AllItems.aspx" target="_blank">
        <b-button class="button1" squared variant="outline-primary">Intellectual Property</b-button></a>
      </div>
    </section>
    <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 col-sm-12 col-12">
        <div class="popup-btn">
          <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=Zomek4WSmkqfBAiH7-iq4KjmSTdmA0dKiKshy8zDXlRUMzdMTEcwUkVSTFFRVFQ5Q1RQTVZKM09ENC4u" target="_blank">Feedback</a>
        </div>
      </div>
    </div>
</div>
  </div>
</template>

<!-- SCRIPT STARTS HERE -->
<script>
  //import rssfeed from './RssFeed'
  //import VueRssFeed from 'vue-rss-feed'

  export default {
    name: 'HomePage',
    data () {
      return {
        //rssName: ' '
        isModernBrowser: true
      }
    },
    components: {
      //rssfeed
    // VueRssFeed
    }
    // mounted () {
    //  var ua = window.navigator.userAgent
    //  var msie = ua.indexOf('MSIE ')
    // check if browser is IE , console.log else set isModernBrowser to true
    ///  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
    // console.log
    //  console.log('IE Detected')
    //  } else {
    // set isModernBrowser to true
    //  console.log('Other Detected')
    //  this.isModernBrowser = true
    // import('./Rssfeed')
    // import('./components/Rssfeed.vue')
    //   }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .page-background{
    background-position: top center;
    background-size: cover;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    position: fixed;
    top: 0;
    z-index: -1;
    opacity: 0.7;
    background-image: url('./../assets/images/goodyear-lobby-md.jpg');
  }
  .main-section {
    min-height: 150px;
    top:50px;
    align-content: center;
  }
  .bannerimg{
    width:100%;
  }
  .prod-section {
    min-height: 350px;
    text-align:center;
    color: #333333;
    background: -webkit-gradient(linear,left top,right bottom,from(#ffffff),to(#ffffff));
    background: linear-gradient(to bottom right,#ffffff,#ffffff);
  }
  .separator {
    background-color: #ffffff00;
    min-height: 25px;
  }
  .fourblock .row {
    margin-right: 0px;
    margin-left: 0px;
  }
  .fourblock .row .col {
    padding-left: 0px;
    padding-right: 0px;
    background-color: #fff;
  }
  .fourblockimages {
    padding-left: 0px;
    padding-right: 0px;
  }
  .block-img{
    height: 100%;
    width: 100%;
    align-items: center;
  }
  .doc-library
  {
    padding: 20px;
    text-align: center;
    color: #fff;
    background-color: #666666;
    min-height: 250px;
  }
  .icon-knowledge-share
  {
    text-align: left;
    padding-left: 0px;
    padding-right: 0px;
  }
  .icon-knowledge-share h4
  {
      text-align: center;
      background-color: #333333;
      color: #fff;
      padding: 10px;
      min-height: 30px;
  }
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
    font-size: 30px;
  }
  .centered:hover{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #FEDB00;
    text-align: center;
    transition: .2s;
    font-size: 35px;
  }
  .button1
  {
    background-color: #fff;
    width: 150px;
    height: 150px;
    text-align: center;
    box-shadow: 1px 1px 1px black;
    margin: 5px;
    font-size: 20px;
  }
  .button1:hover
  {
    background-color: #004EA8;
  }
  .vertical-left {
    border-left: 1px solid black;
    height: 375px;
    position:absolute;
    left: 25%;
  }
  .vertical-middle {
    border-left: 1px solid black;
    height: 375px;
    position:absolute;
    left: 50%;
  }
  .vertical-right {
    border-left: 1px solid black;
    height: 375px;
    position:absolute;
    left: 75%;
  }
  .news-box
  {
    background-color: #004EA8;
    text-align: center;
    color: #FEDB00;
    height: 40px;
    padding: 10px;
    border-color: #333333;
  }
  .news-placeholder
  {
    background-color: #fff;
    text-align: left;
    color: #000;
    padding: 10px;
    border-color: #333333;
  }
  .icon-link{
  color: black;
  }
  .fa-fw
  {
    margin-right: 10px;
  }
  .breaking-news {
    background-color: #004EA8;
    color: #FEDB00;
    font-weight: 700;
    border-radius: 0px 0px 0px 0px;
    width: 75px;
    text-align: center;
    padding: 10px;
  }

  .media-content {
    padding: 8px;
    background-color: #fff;
  }

  .time {
    color: #333333;
    font-weight: 700;
  }

  .news {
    color: #333333;
  }
  .my-btn
  {
    background-color: transparent;
    border: none;
    color:#333333;
  }
  .NewsHeader{
    color: #FEDB00;
    background-color: #004EA8;
    text-align: center;
    font-size: 25px;
    border-radius: 0.25rem
  }
  .newstile1{
    width: 100%;
    margin-top: 30px;
  }
  .vue-rss-feed{
    align-content: center;
  }
  .centered3 {
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
    font-size: 28px;
    font-weight: 400;
  }
  .centered3:hover{
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #FEDB00;
    text-align: center;
    transition: .2s;
    font-size: 30px;
  }
  .social-media{
    width: 45px;
    padding-top: 15px;
    position: relative;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .social-media-row{
    align-content: center;
    position: relative;
    top: -20%;
    left: 54%;
    transform: translate(-50%, -50%);
    width: 250px;
  }
  .social-media1 {
      width: 45px;
      width: 45px;
      padding-top: 15px;
      position: relative;
      top: 20%;
      left: 100%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
  }
  .social-media2 {
      width: 45px;
      width: 45px;
      padding-top: 15px;
      position: relative;
      top: 20%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
  }
  @media (min-width:768px) and (max-width:1092px) {
    .icon-knowledge-share h4 {
        min-height: 79px;
    }
  }
  @media (max-width: 445px) {
    .page-background {
      background-image: url('./../assets/images/goodyear-lobby-xs.jpg');
    }
    .centered3 {
      font-size: 20px;
    }
    .centered3:hover {
      font-size: 22px;
    }
    .bannerimg{
      max-width: 100%;
      max-height: 76px;
    }

  }
  @media (min-width:446px) and (max-width: 768px) {
    .page-background {
      background-image: url('./../assets/images/goodyear-lobby-sm.jpg');
    }
    .bannerimg{
      max-width: 800px;
    }
  }
  @media (min-width:1281px) and (max-width: 1600px) {
    .page-background {
      background-image: url('./../assets/images/goodyear-lobby-lg.jpg');
    }
    .bannerimg{
      max-width: 1640px;
    }
  }
  @media (min-width: 1601px) and (max-width: 2500px) {
    .page-background {
      background-image: url('./../assets/images/goodyear-lobby-xl.jpg');
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .centered3{
      font-size: 20px;
      top: 65%;
    }
    .centered3:hover{
      font-size: 22px;
      top: 65%;
    }
    .social-media-row{
      top: -12%;
      left: 57%;
      width: 190px;
    }
    .social-media
    {
      width: 45px;
    }
  }
  .popup-btn a{
      top: 117px;
      position: fixed;
      right: -34px;
      z-index: 1000;
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
      background-color: red;
      padding: 2px 20px 30px;
      height: 0px;
      background-color: #FEDB00;
      color: black;
  }
  .popup-btn a:hover{
      text-decoration: underline;
      color:#004EA8;
  }
</style>
