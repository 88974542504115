<template>
  <div class="error-404">
      <b-container>
        <b-row>
          <b-col sm="12">
            <h1>Sorry about that - we couldn't find the page you're looking for.</h1>
            <p>You may have clicked a link to a page that no longer exists, or it's possible there was a typo in the URL.</p>
            <router-link to="/" class="gy-btn btn backHome">Visit homepage</router-link>
          </b-col>
        </b-row>
      </b-container>
  </div>
</template>

<script>
export default {
  name: 'NotFoundPage',
  title: 'Page Not Found | Legal KM'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.error-404 {
  min-height: 500px;
  padding-top: 50px;
  margin-bottom: 25px;
  text-align: center;
}
h1 {
  font-weight: bold;
  font-size: 32px;
  color: #274ba7;
}

</style>
