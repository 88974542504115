import Vue from 'vue'
import VueRouter from 'vue-router'
import { msalPluginInstance } from '@/plugins/MSALPlugin'
import Home from './views/Home.vue'
//import Home2 from './views/Home2.vue'
import Login from './views/Login.vue'
import Logout from './views/Logout.vue'
import NotFound from './views/404.vue'
import AccessDenied from './views/AccessDenied.vue'
import store from './store'
import { roles } from "./authConfig"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
/*
  {
      path: '/test',
      name: 'home2',
      component: Home2
    },
*/
    {
      path: '/signin',
      name: 'signin',
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/logout',
      name: 'logout',
      component: Logout
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: NotFound
    },
    {
      path: '/access-denied',
      name: 'access-denied',
      component: AccessDenied
    },
    {
      path: '*',
      redirect: '/error-404'
    }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: () => {
    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  // Authentication
  if(!msalPluginInstance.isAuthorized(roles.USER)){
    if ('signin' == to.name) {
      await msalPluginInstance.signIn();
    } else if ('login' == to.name || 'logout' == to.name || 'access-denied' == to.name) {
      next();
      return;
    } else {
      router.replace({name: 'login'});
      return;
    }
  }

  if(msalPluginInstance.isAuthorized(roles.USER)) {
    store.commit('authorizeUser', true);
    if ('signin' == to.name || 'login' == to.name || 'logout' == to.name || 'access-denied' == to.name) {
      router.replace({name: 'home'});
    } else {
      next();
    }
  } else {
    await msalPluginInstance.signOutAndAccessDenied();
  }
});

export default router