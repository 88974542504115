<template>
    <div class="Footer">
      <footer>
        <b-row class="footer-end">
              <b-col sm="12" md="6">
                <div>&copy; {{ new Date().getFullYear() }} The Goodyear Tire & Rubber Company</div>
              </b-col>
              <b-col sm="12" md="6">
                <a class="footLink" href="https://corporate.goodyear.com/us/en/more/terms-conditions-and-privacy-policy.html" target="_blank">Terms & Conditions and Privacy Policy</a>
              </b-col>
            </b-row>
      </footer>
    </div>
</template>

<!-- Script starts below -->
<script>
export default {
  name: 'BaseFooter'
}
</script>

<!-- Style starts below -->
<style scoped>
footer{
  background-color: #004EA8;
  background: #004EA8;
  padding: 15px 15px 20px 15px;
  overflow: hidden;
  border-top: 2px solid #FEDB00;
  color: #fff;
  min-height: 20px;
  text-align: center;
  font-size: 15px;
}
.footLink
{
  color: #FEDB00;
}
</style>
